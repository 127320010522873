<template>
	<div class="login-panel">
		<div class="login-box" :style="{'margin-top':mtop}">
			<el-row :gutter="20">
				<el-col :span="15">
					<div class="grid-content" style="height: 300px;">
						<img v-if="false" src="../assets/images/login_pic.png" width="100%" />
					</div>
				</el-col>
				<el-col :span="9"><div class="grid-content">
					<div style="margin-top: 40px; text-align: center;margin-bottom: 70px;font-size: 24px;font-weight:bold;">
						医院统一登录平台
					</div>
					<div style="text-align: center;margin-bottom: 30px;">
						<img src="../assets/images/logo-login.png" />
					</div>
					<el-input placeholder="请输入账号" v-model="phone_number">
						<i slot="prefix" class="el-input__icon el-icon-user"></i>
					</el-input>
					<div style="height: 15px;"></div>
					<el-input placeholder="请输入密码" type="password" v-model="login_pswd">
						<i slot="prefix" class="el-input__icon el-icon-lock"></i>
					</el-input>
					<div style="height: 35px;"></div>
					<button type="button" class="login-btn"  @click="checkLogin" ></button>
				</div></el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	name: 'login',
	components: {
		//
	},
	props: [],
	data() {
		return {
			mtop: "100px",
			phone_number: '',
			login_pswd: '',
		};
	},
	mounted() {
		this.mtop = (window.innerHeight-577)/2+'px';
	},
	methods: {
		checkLogin(){
			if(this.phone_number==''){
				this.$alert('请输入账号');
				return;
			}
			if(this.login_pswd==''){
				this.$alert('请输入密码');
				return;
			}
			this.doLogin()
		},
		doLogin(){
			let _data= {
				"name": this.phone_number,
				"pwd": this.login_pswd
			};
			this.$axios.post('/users/adminlogin', _data).then(res => {
				if(res.status!=200){
					this.$message({
						message: "账号或密码错误",
						type: 'warning'
					});
				}else{
					this.$message({
						message: '登录成功！',
						type: 'success'
					});
					window.sessionStorage.setItem('user', this.phone_number);
					window.sessionStorage.setItem('token', res.data.data[0]['token']);
					this.$router.push({path: '/'});
				}
			}).catch(()=>{
				this.$message({
					message: "账号或密码错误",
					type: 'warning'
				});
			})
		}
	}
}
</script>

<style scoped>
	.login-panel{position: absolute;top: 0px;left: 0px;right: 0px; bottom: 0px;background:#fff;}
	.login-box{
		background-color: #fff; padding: 50px;
		width: 1024px;
		height: 577px;margin: 100px auto;border-radius: 0px;
		background-image: url('../assets/images/bg.jpg');
		background-repeat: no-repeat;
		background-position: center center;
		box-sizing: border-box;
	}
	.login-btn{
		background-color: #AE1C28;
		background-image: url('../assets/images/s.gif');
		border: none;
		width: 100px;
		height: 34px;
		background-repeat: no-repeat;
		background-position: center center;
		margin-left: 30%;
	}
</style>
